

/*//////////////
// FOOTER
/////////////*/

.footer {
	color: $grey-darker;
	font-size: 0.7rem;
}

.footer-content {
	display: flex;
	align-items: flex-start;
}

.footer-link {
	text-decoration: none;
	color: inherit;
	font-weight: normal;

	&:hover {
		color: $black;
		text-decoration: none;
	}
}

.footer-cecaz {
	flex-grow: 5;
	text-align: right;
	border-right: 1px solid $grey;
}

.footer-socials {
	flex-shrink: 0;
}

.social-icon {
	border-radius: 50%;
	background-color: $grey-darker;
	color: $white;
	width: 2em;
	height: 2em;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;

	&:hover {
		background-color: $black;
		color: $white;
		text-decoration: none;
	}
}

@media (max-width: 1024px) {
	.footer-content {
		flex-direction: column;
		align-items: stretch;
	}

	.footer-copyright {
		order: 5;
	}

	.footer-legal-wrap {
		order: 4;
		text-align: center;
	}
	.footer-legal, .footer-charte {
		text-align: center;
	}
	.footer-charte {
		order: 3
	}
	.footer-socials {
		text-align: center;
		order: 2;
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	.footer-cecaz {
		order: 1;
		border: none;
		padding: 0;
		text-align: center;
	}
}
