
/*//////////////
// COLORS
/////////////*/

$red-cecaz: #db0029 !default;
$red-cecaz-lighter: #d20c31 !default;
$grey: #8d8d8d !default;
$grey-light: #c1c1c1 !default;
$grey-lighter: #e8e8e8 !default;
$grey-darker: #5b5b5b !default;
$green-success: #8cd306 !default;

/*//////////////
// OVERRIDE BOOTSTRAP
/////////////*/

$danger: $red-cecaz;
$secondary: $grey-light;

$theme-colors: (
	"grey": $grey
);

//** Global textual link color.
$link-color: $grey-darker;
//** Link hover color set via `darken()` function.
$link-hover-color: #000;
//** Link hover decoration.
$link-hover-decoration: underline;

$screen-xl: 1200px;
