

/*//////////////
// BAN TITLE
/////////////*/

.title-banner {
	position: relative;
	z-index: 15;
	margin-top: -150px;
	cursor: pointer;
}

.ban {
	background: url("../../images/ban-title-small.jpg") no-repeat;
	background-color: white;
	background-size: auto 100%;
	background-position: right top;
	padding-bottom: 35px;
	position: relative;

	h2 {
		font-weight: 200;
		font-size: 2em;
		line-height: 1;

		span {
			font-weight: bold;
		}
	}

	.red-circle {
		font-size: 0.9em;
	}
}

.ban-text {
	line-height: 1.1;
}

.ban-text-container {
	position: absolute;
	bottom: 0;
}

.ban-content {
	color: $black;
}
@include media-breakpoint-down(md) {
	.ban-text {
		p {
			width: 60%;
			font-size: 0.8em;
			br {display: none;}
			margin-bottom: 0.5rem;;
		}

	}
}
@include media-breakpoint-down(sm) {
	.ban,
	.ban-text {
		p {
			font-size: 0.7em;
			width: 42%;
		}
	}
}

@include media-breakpoint-down(xs) {
	.title-banner {
		margin-top: 0;
		padding: 0;
	}

	.ban {
		font-size: 0.6em;
		padding-bottom: 0;
	}

	.ban-text-container {
		position: static;
	}

	.ban-text {
		padding-top: 16px;
		text-align: justify;
		p {
			width: 100%;
			font-size: 0.8em;
			br { display: block;}
		}
	}
}
