.FormFaqModificationAdresse {
	.steps li {
		min-height: 380px;
		img {
			box-shadow: none !important;
		}
	}
	.steps li p {
		font-weight: bold;
	}
}

.bordered {
	border: 1px solid;
	padding: 5px;
}

.noShadow {
	box-shadow: none !important;
}

.faq ol > li::marker {
	font-weight: bold;
}
.ol-steps {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	flex-wrap: wrap;

	li {
		margin-bottom: 1.5rem;
		margin-right: 3rem;
		img {
			box-shadow: none !important;
		}
		p {
			display: block;
			width: 100%;
			margin-top: 0.2rem;
			text-align: center;
			font-weight: bold;
			border: 1px solid;
			padding: 5px;
			font-size: 0.9rem;
		}
	}


}


.faq {
	h4 {
		font-size: 1.1rem;
		margin-bottom: 5px;
		text-decoration: underline;
	}
}
