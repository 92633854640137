
/*//////////////
// FORM
/////////////*/

.demand-form {
	height: auto;
}

.form-title {
	display: flex;
	align-items: center;
	font-size: 1.3em;
}

.drop-file {
	> input[type="file"] {
		width: 100%;
	}
}

label {
	text-align: right;
	vertical-align:top;
	padding-top: 6px;
	color: $black;
}

.form-check-label {
	color: inherit;
	text-align: left;
}

.strongs {
	font-weight: bold;
	color: $black;
}

select {
	cursor: pointer;
}

 /*////////////
 // BUTTONS
 ////////////*/

 .btn-xl {
	 padding: 10px 55px 10px 55px;
 }

/*////////////
// FEEDBACK
////////////*/

.invalid-feedback {
	p {
		color: $red-cecaz;
	}
}

.field-error.is-submitted {
	display: block;
	text-align: left;
}

.field-error.is-submitted.files {
	padding: 10px;
	margin-left: 5%;
	text-align: center;
	width: 100%;
	color: $grey-darker !important;
}


.has-errors.is-submitted,
.has-errors.is-touched,
.has-errors.is-touched > .Select-control,
.has-errors.is-submitted .Select-control {
	border-color: $red-cecaz;
}

.success, .error {
    justify-content: center;
	align-items: center;
	margin-bottom: 1.5rem;
	display: flex;
	color: $black;

	.i {
		margin-right: 15px;
	}
	p {
		margin-bottom: 0;
	}
}

/*////////////
// FILE UPLOAD
////////////*/

.selected-files {
	padding: 6px 0 6px .75rem;
	border: 1px solid transparent;
}

 .file {
	 display: flex;
	 margin-bottom: 8px;
	 align-items: center;
	 .invalid-feedback {
		 margin-top: 0;
	 }
	 .i {
		color: $grey-darker;
		padding-left: 10px;
		cursor: pointer;
		margin-left: auto;
	 }
 }

 .file-mentions {
    font-size: .8em;
    color: $grey;
    padding: 5px;
 }

 /*////////////
 // HINT
 ////////////*/

 .hint {
 	background-color: $grey-darker;
 	border-radius: 5px;
	text-align: center;
 	margin-top: 10px;
 	font-size: .9em;
 	height: auto;
 	color: white;

  	> .content {
 		padding: 17px;
 		margin: 0;

 		a {
 			color: white;
 		}
 	}
 }

 /*////////////
 // SWITCH
 ////////////*/

 .switch-container {
 	padding-left: .75rem;
 	border: 1px solid transparent;
 	padding-top: 6px;

 	> div {
 		vertical-align: bottom;
 		margin-top: -13px;
 		margin-left: 7px;
 		margin-right: 7px;
 	}
 }

/*////////////
// MEDIAS
////////////*/

@include media-breakpoint-down(sm) {
	label {
		text-align: left;
	}

	.switch-container {
		//text-align: center;
		padding-left: 0;
	}

	.form-title {
		flex-direction: column;
		justify-content: center;

		p {
			text-align: center;
			font-size: .9em;
		}
	}
}
