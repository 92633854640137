@import '../styles/variables.scss';

.TitleDesktopMobile {
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: $grey-darker;
		color: #fff;
		border-radius: 50%;
		color: #fff;
		width: 1.25em;
		height: 1.25em;
	}
}
