
/*//////////////
// HEADER
/////////////*/

.header {
	width: 100%;
	color: white;
	min-height: 600px;
	position: relative;
	margin-top: 80px;
}

.header-title {
	top: 0;
	position: absolute;
	padding-top: 40px;
	padding-bottom: 20px;
	z-index: 15;
	width: 100%;
	font-size: 2em;
}

.xl-title {
	vertical-align: middle;
	font-weight: normal;
	color: $red-cecaz;
	font-size: 2.3em;
	line-height: 1;

	.talkImg {
		height: 0.9em;
	}
}

.md-title {
	font-weight: 200;
	display: flex;
	flex-direction: column;
	font-size: 1.1em;

	> strong {
		font-weight: bold;
		margin-top: 4vh;

		&::before {
			width: 70px;
			content: '';
			display: block;
			margin-bottom: 20px;
			border-bottom: 3px solid white;
		}
	}
}

/*//////////////
// CAROUSEL
/////////////*/

.carousel-inner {
	min-height: 600px;
}

.carousel-inner, .carousel-item, .carousel-img-container {
	height: 80vh;
}

.carousel-img-container {
	width: 100%;
	opacity: 0.9;
	min-height: 300px;
	background-size: cover;

	 img {
		width: 100%;
	}
}

/*////////////
// MEDIAS
////////////*/

@include media-breakpoint-down(md)  {
	.header, .carousel-inner, .carousel-item, .carousel-img-container {
		height: 50vh;
		min-height: inherit;
	}

	.header {
		margin-top: 60px;
	}

	.header-title {
		font-size: 1.3em;
	}
}

@include media-breakpoint-down(xs) {
	.header {
		height: 400px;
	}

	.bottom-text {
		position: absolute;
		bottom: 0;
	}

	.carousel-img-container {
		height: 100%;

		> img {
			height: 450px;
			width: auto;
			min-width: 100%;
		}
	}

	.header-title {
		height: 100%;
		font-size: 1.8em;
	}

	.xl-title {
		display: block;
		text-align: center;
		font-size: 1.4em;

		.talkImg {
			display: block;
			margin: auto;
		}
	}

	.md-title {
		font-size: 0.6em;
	}
}
