.head-mentions, .head-404 {
	background: url("../../images/slider-vignes-filter.jpg");
	background-size: cover;
}

.head-charte {
	background: url("../../images/slider-village-filter.jpg");
	background-size: cover;
}

.head-simple .header {
	min-height: 400px;
	.xl-title {
		font-size: 2em;
	}
}

.mentions-content {
	h2 {
		font-size: 1.4em;
		margin-top: 8px;
		margin-bottom: 8px;
	}

	p {
		text-align: justify;
	}

}
