.Cookies {
	.custom-control-input:checked ~ .custom-control-label::before {
		background-color: #db0029;
		border-color: #db0029;
	}
	.custom-control-input:focus ~ .custom-control-label::before {
		box-shadow: 0 0 0 0.2rem #db002940
	}
	.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
		border-color: #f49ead;
	}
}
